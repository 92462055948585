/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  faAdd,
  faCalendar,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Dropdown, Row, Space, Grid, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import BtnComp from "./../../buttonComponent/BtnComp";
import { DatePicker } from "antd";
import { CalendarOutlined, CaretDownOutlined } from "@ant-design/icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import NewInputComp from "./../../input/NewInputComp";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";

import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { hotelAdvanceSearch } from "../../../../services/utils/userUtils/hotelutils";
import {
  setSearchCardData,
  setSearchToggle,
} from "../../../../reduxData/sliceData/searchCardReducer";
import CalenderRangePicker from "../../calendarComponent/CalenderRangePicker";
import SelectInput from "./SearchInput";

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const num2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const { RangePicker } = DatePicker;

function SearchCard() {
  const currentDate = moment().startOf("day").format("DD/MM/YYYY");
  const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const [dropDownOn, setdropDownOn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOptionsArray, setDropdownOptionsArray] = useState([]);
  const [loading, setloading] = useState(false);
  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );
  const [searchValue, setsearchValue] = useState(searchCardData?.destinationName);
  const searchToggle = useSelector(
    (state) => state?.searchCardData?.searchToggle
  );
  const dispatch = useDispatch();
  const [searchBoxData, setsearchBoxData] = useState({...searchCardData});
  const { state } = useLocation();
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);

  const sliderStetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    color: "red",
  };
  const [destinationError, setdestinationError] = useState(false);

  const mainSearchBtn = () => {

    if (!searchBoxData?.destinationName) {
      setdestinationError(true);
      return;
    }

    dispatch(setSearchToggle(searchToggle + 1));


    navigate(
      searchBoxData.destinationName == "Kochi"
        ? `/hotels/search-hotels-in-${searchBoxData.destinationName}/cochin-hotels.html`
        : searchBoxData.destinationName == "Kozhikode"
          ? `/hotels/search-hotels-in-calicut/${searchBoxData.destinationName}-hotels.html`
          : `/hotels/search-hotels-in-${searchBoxData.destinationName}-hotels.html`,
      {
        state: {
          destination: searchBoxData.destinationName,
          data: searchCardData,
        },
      }
    );
    //guestApply();
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const openCalenderPicker = () => {
    setShowCalendarPicker(true);
  }

  const guestAdd = (field) => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        [field]: Number(o.guestDetails[field]) + 1,
      },
    }));
  };

  const guestSub = (field) => {
    let i = 1;
    if (field != "children") {
      i = 1;
    } else {
      i = 0;
    }
    if (searchBoxData?.guestDetails[field] > i) {
      setsearchBoxData((o) => ({
        ...o,
        guestDetails: {
          ...o.guestDetails,
          [field]: Number(o.guestDetails[field]) - 1,
        },
      }));
    }
  };

  async function guestApply() {
    setDropdownOpen(false);
    const {
      destinationName,
      checkIn,
      checkOut,
      guestDetails: { adults, childAge, children, rooms },
    } = searchBoxData;

    try {
      const payload = {
        destinationName: destinationName,
        checkIn: checkIn ? checkIn : currentDate,
        checkOut: checkOut ? checkOut : nextDate,
        guestDetails: {
          rooms: rooms,
          adults: adults ? adults : 2,
          children: children ? children : 0,
          childAge: childAge,
        },
      };
      dispatch(setSearchCardData(payload));
      //await dispatch(searchApi(payload));
    } catch (err) {
      console.log(err);
    }
  }

  const hotelSearchDropdown = async (e) => {
    try {
      if (e.length >= 3) {
        setloading(true);
        const res = await hotelAdvanceSearch(e);

        if (res?.data?.length > 0) {
          let result = res?.data.map(function (obj, index) {
            return JSON.stringify(obj);
          }).filter(function (value, index, self) {
            return self.indexOf(value) === index;
          }).map(function (value) {
            return JSON.parse(value);
          });
          setDropdownOptionsArray(result);

        } else {
          setDropdownOptionsArray([]);
        }

        setloading(false);
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    hotelSearchDropdown(searchValue);
    setsearchBoxData({ ...searchCardData, destinationName: searchValue });
  }, [searchValue]);

  const childAdd = () => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        childAge: [...o.guestDetails.childAge, 0],
        children: o.guestDetails.childAge.length + 1,
      },
    }));
  };

  const childSub = () => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        childAge: [
          ...o.guestDetails.childAge.slice(1, o.guestDetails.childAge.length),
        ],
        children: o.guestDetails.childAge.length - 1,
      },
    }));
  };

  const items = [
    {
      key: "1",
      label: (
        <Row gutter={10}>
          <Col
            lg={12}
            md={24}
            sm={24}
            sx={24}
            style={{ borderRight: "1px solid #ccc" }}
          >
            <Row className="mt-3">
              <p className="h-4">
                <b>Rooms</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestSub("rooms")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchCardData?.guestDetails?.rooms
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  {" "}
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestAdd("rooms")}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <p className="h-4">
                <b>Adults</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestSub("adults")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchCardData?.guestDetails?.adults
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  {" "}
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestAdd("adults")}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <p className="h-4">
                <b>Children (1 - 12yrs)</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div className="childAgeboxbtn" onClick={() => childSub()}>
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchCardData?.guestDetails?.childAge.length
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  <div className="childAgeboxbtn" onClick={() => childAdd()}>
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col lg={12} md={24} sm={24} sx={24} style={{ padding: "2%" }}>
            <p className="h-4">
              <b>Select Child Age </b>
            </p>
            <p style={{ color: "#ccc", fontSize: "0.9rem" }}>
              Set exact child age to find the best deals
            </p>
            <p className="h-4">
              <b>Child Age</b>
            </p>

            <Row
              gutter={[0, 5]}
              style={{
                padding: "2%",
                paddingLeft: "8%",
                height: "150px",
                overflow: "auto",
              }}
            >
              {searchCardData?.guestDetails?.childAge.map((_, cIndex) => {
                return (
                  <Col span={22} key={cIndex} style={{ width: "60px" }}>
                    <Slider className="slideAge" {...sliderStetting}>
                      {num.map((v, i) => {
                        return (
                          <Col span={6} key={i}>
                            <div
                              key={i}
                              className={`${searchCardData?.guestDetails?.childAge[
                                cIndex
                              ] === v
                                ? "childAge childAgeHover"
                                : "childAge"
                                }`}
                              onClick={() => {
                                setsearchBoxData((o) => {
                                  let childAgeArray = [
                                    ...o.guestDetails.childAge,
                                  ];
                                  childAgeArray[cIndex] = v;

                                  return {
                                    ...o,
                                    guestDetails: {
                                      ...o.guestDetails,
                                      childAge: childAgeArray,
                                    },
                                  };
                                });
                              }}
                            >
                              {v}
                            </div>
                          </Col>
                        );
                      })}
                    </Slider>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Button className="applybtn" onClick={guestApply}>
            Apply
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setSearchCardData({ ...searchBoxData }));
  }, [searchBoxData]);

  return (

    <>
      {showCalendarPicker && <CalenderRangePicker hideShowCalendarPicker={() => setShowCalendarPicker(false)} searchBoxData={searchBoxData} setsearchBoxData={(data) => setsearchBoxData(data)} />}

      <div className="container" style={{ position: "relative", zIndex: 4 }}>
        <div className="searchMainDiv">
          <Row>
            <Col lg={19} md={19} sm={24} xs={24}>
              {/* <NewInputComp */}

              <SelectInput state={state} loading={loading} destinationError={destinationError} setsearchValue={(val) => setsearchValue(val)} setsearchBoxData={(val) => setsearchBoxData(val)} dropdownOptionsArray={dropdownOptionsArray} />
              <Row
                style={{
                  width: "100%",
                  borderTop: `${xs ? "none" : "1px solid #ccc"}`,
                  paddingTop: "5px",
                }}
                className="win-dropdown"
              >
                <Col span={15}>
                  <Row>
                    <Col span={12}>
                      <b>Check-In</b>
                    </Col>
                    <Col span={12}>
                      <b> Check-Out</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div
                        className="dateoutline"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FontAwesomeIcon
                          color={"#1675DF"}
                          style={{ padding: "10px 0 10px 10px" }}
                          icon={faCalendar}
                        />

                        <RangePicker
                          prefixIcon={<CalendarOutlined />}
                          format="DD/MM/YYYY"
                          placeholder={[
                            searchCardData?.checkIn
                              ? moment(
                                searchCardData?.checkIn,
                                "DD-MM-YYYY"
                              ).format("DD/MM/YYYY")
                              : currentDate,
                            searchCardData?.checkOut
                              ? moment(
                                searchCardData?.checkOut,
                                "DD-MM-YYYY"
                              ).format("DD/MM/YYYY")
                              : nextDate,
                          ]}
                          disabledDate={disabledDate}
                          onChange={(_, s) => {
                            let searchData = {
                              ...searchBoxData,
                              checkIn: s[0],
                              checkOut: s[1],
                            };
                            setsearchBoxData(searchData);
                          }}
                          separator={
                            <>
                              <FontAwesomeIcon
                                color={"#1675DF"}
                                className="vertical-line"
                                icon={faCalendar}
                              />
                            </>
                          }
                          suffixIcon=""
                          className="serchInputcard"
                          style={{ width: "98%", marginRight: "15px" }}
                          dateRender={(current) => {
                            return (
                              <div
                                className="ant-picker-cell-inner serchInputcard"
                                style={{ color: "#212529" }}
                              >
                                {current.date()}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={9} style={{ cursor: "pointer" }}>
                  <Row>
                    <Col lg={24} md={24} sm={12} xs={12}>
                      <b>Guest</b>
                    </Col>
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ cursor: "pointer", paddingTop: "6px" }}
                    >
                      <Dropdown
                        style={{ cursor: "pointer" }}
                        placement="bottomRight"
                        overlayClassName="GuestDropdown"
                        open={dropdownOpen}
                        onClick={() => setDropdownOpen((prev) => !prev)}
                        menu={{
                          items,
                        }}
                      >
                        <Space>
                          <p
                            style={{
                              color: "#ccc",
                              whiteSpace: "noWrap",
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faUser}
                              color={"#1675DF"}
                              className="guest-dropdown-icon"
                            />
                            {searchCardData?.guestDetails?.rooms ? (
                              <>
                                {` Rooms : ${searchCardData?.guestDetails.rooms
                                  .toString()
                                  .padStart(2, "0")} Guest: ${(
                                    searchCardData?.guestDetails?.adults +
                                    searchCardData?.guestDetails?.children
                                  )
                                    .toString()
                                    .padStart(2, "0")}`}
                              </>
                            ) : (
                              <>&nbsp; Guest details &nbsp;</>
                            )}
                            &nbsp;
                            {/* <DownOutlined  /> */}
                            <CaretDownOutlined style={{ color: "#ccc" }} />
                          </p>
                        </Space>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                style={{ width: "100%" }}
                className="mobile-date just-center mt-2"
              // gutter={20}
              >
                <Row className="mobileCheckInOut">
                  <Col span={12} className="checkInCol">
                    <NewInputComp readOnly={true}
                      value={
                        searchBoxData?.checkIn
                          ? moment(searchBoxData?.checkIn, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )
                          : currentDate
                      }
                      onClick={() => openCalenderPicker()}
                      format="DD/MM/YYYY"
                      label={
                        <b className="checkInOut">
                          <FontAwesomeIcon color={"#1675DF"} icon={faCalendar} />{" "}
                          &nbsp; Check-In
                        </b>
                      }
                      star="none"
                      className={"serchInputcard"}
                    // placeholder={'Destination, City, Hotel Name'}
                    />
                  </Col>
                  <Col span={12} className="checkOutCol">
                    <NewInputComp readOnly={true}
                      value={
                        searchCardData?.checkOut
                          ? moment(searchCardData?.checkOut, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )
                          : nextDate
                      }
                      onClick={() => openCalenderPicker()}
                      format="DD/MM/YYYY"
                      label={
                        <b className="checkInOut">
                          <FontAwesomeIcon color={"#1675DF"} icon={faCalendar} />{" "}
                          &nbsp; Check-Out
                        </b>
                      }
                      star="none"
                      className={"serchInputcard"}
                    // placeholder={'Destination, City, Hotel Name'}
                    />
                  </Col>
                </Row>
                <div className="guestAndBtn">
                  <Col
                    span={12}
                    className={`"guestBtn" ${dropDownOn ? "pt-2" : ""}`}
                  >
                    <b className={`"guestText" ${dropDownOn ? "h-4" : ""}`}>
                      <FontAwesomeIcon
                        color={"#1675DF"}
                        className="guestIcon"
                        icon={faUser}
                      />
                      &nbsp;Guest
                    </b>
                  </Col>
                  <Col span={12} className="clickBtn">
                    {!dropDownOn && (
                      <BtnComp
                        btnName="Click here"
                        className="click-here-btn"
                        btnClickFun={() => setdropDownOn(true)}
                      />
                    )}
                  </Col>
                </div>
                {dropDownOn && (
                  <div style={{ padding: "5%", marginTop: "5px" }}>
                    <Row style={{ width: "100%", marginTop: "-15px" }}>
                      <p className="userDetails">
                        <b style={{ paddingLeft: "30%" }}>Rooms</b>
                      </p>
                      <Row
                        style={{ width: "100%", paddingTop: "2%" }}
                        justify="space-around"
                      >
                        <Col span={4}>
                          <div
                            className="childAgeboxbtn"
                            onClick={() => guestSub("rooms")}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </div>
                        </Col>
                        <Col span={8} className="childBoxtxt">
                          {searchBoxData?.guestDetails &&
                            searchBoxData?.guestDetails?.rooms
                              .toString()
                              .padStart(2, "0")}
                        </Col>
                        <Col span={4}>
                          {" "}
                          <div
                            className="childAgeboxbtn"
                            onClick={() => guestAdd("rooms")}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </div>
                        </Col>
                      </Row>
                    </Row>
                    <Row style={{ width: "100%", marginTop: "5px" }}>
                      <p className="userDetails">
                        <b style={{ paddingLeft: "30%" }}>Adults</b>
                      </p>
                      <Row
                        style={{ width: "100%", paddingTop: "2%" }}
                        justify="space-around"
                      >
                        <Col span={4}>
                          <div
                            className="childAgeboxbtn"
                            onClick={() => guestSub("adults")}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </div>
                        </Col>
                        <Col span={8} className="childBoxtxt">
                          {searchBoxData?.guestDetails &&
                            searchBoxData?.guestDetails?.adults
                              ?.toString()
                              .padStart(2, "0")}
                        </Col>
                        <Col span={4}>
                          {" "}
                          <div
                            className="childAgeboxbtn"
                            onClick={() => guestAdd("adults")}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </div>
                        </Col>
                      </Row>
                    </Row>
                    <Row style={{ width: "100%", marginTop: "5px" }}>
                      <p className="userDetails">
                        <b style={{ paddingLeft: "11px" }}>
                          Children (1 - 12yrs)
                        </b>
                      </p>
                      <Row
                        style={{ width: "100%", paddingTop: "2%" }}
                        justify="space-around"
                      >
                        <Col span={4}>
                          <div
                            className="childAgeboxbtn"
                            onClick={() => childSub()}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </div>
                        </Col>
                        <Col span={8} className="childBoxtxt">
                          {searchBoxData?.guestDetails &&
                            searchBoxData?.guestDetails?.children
                              .toString()
                              .padStart(2, "0")}
                        </Col>
                        <Col span={4}>
                          {" "}
                          <div
                            className="childAgeboxbtn"
                            onClick={() => childAdd()}
                            style={{ height: "33px", width: "30px" }}
                          >
                            <FontAwesomeIcon icon={faAdd} />
                          </div>
                        </Col>
                      </Row>
                    </Row>
                    <Col
                      lg={12}
                      md={24}
                      sm={24}
                      sx={24}
                      style={{ padding: "2%" }}
                      className="selectChildAge"
                    >
                      <p>
                        <b>Select Child Age</b>
                      </p>
                      <p style={{ color: "#ccc", fontSize: "0.9rem" }}>
                        Set exact child age to find the best deals
                      </p>
                      <p>
                        <b>Child Age</b>
                      </p>
                      {searchBoxData?.guestDetails?.childAge.map((_, cIndex) => {
                        return (
                          <div key={cIndex}>
                            <div className="agedivscroll">
                              {num2.map((v, i) => {
                                return (
                                  <Col span={3} key={i}>
                                    <div
                                      key={i}
                                      className={`${searchBoxData?.guestDetails?.childAge[
                                        cIndex
                                      ] === v
                                        ? "childAge childAgeHover"
                                        : "childAge"
                                        }`}
                                      onClick={() => {
                                        setsearchBoxData((o) => {
                                          let childAgeArray = [
                                            ...o.guestDetails.childAge,
                                          ];
                                          childAgeArray[cIndex] = v;

                                          return {
                                            ...o,
                                            guestDetails: {
                                              ...o.guestDetails,
                                              childAge: childAgeArray,
                                            },
                                          };
                                        });
                                      }}
                                    >
                                      {v}
                                    </div>
                                  </Col>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                    <p
                      className="just-center"
                      onClick={() => setdropDownOn(false)}
                    >
                      <FontAwesomeIcon icon={faXmark} size="lg" />
                    </p>
                  </div>
                )}
              </Row>
            </Col>
            <Col lg={5} md={5} sm={24} xs={24} className="just-reverse">
              <BtnComp
                btnClickFun={mainSearchBtn}
                btnName="Search"
                className="searchbtn"
              />{" "}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SearchCard;
