/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Paper } from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../styles/global.scss";
// import hotelCard from "../../../../assets/hotelCard.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  setRoomPrice,
  setSelectedRoom,
} from "../../../../reduxData/sliceData/hotelReducer";
import { setSelectedRoomRate } from "../../../../reduxData/sliceData/searchCardReducer";
import { useSelector } from "react-redux";
import { differenceInDays, parse } from "date-fns";
import { hotelConfermFunction } from "../../../../services/utils/userUtils/hotelutils";
import soldout from "../../../../assets/soldout.png";

const HotelRoomCard = ({ showModal, data, dispatch }) => {
  const nav = useNavigate();

  const selectedHotelData = useSelector(
    (state) => state?.hotelAllData?.selectedData
  );

  const [dataForBilling, setDataForBilling] = useState({
    rooms: 0,
    roomRateWithDiscount: 0,
    roomRateWithOutDiscount: 0,
    discountRate: 0,
    gstAmount: 0,
    payableAmount: 0,
    adults: 0,
    childrens: 0,
  });

  const { filterData: datas } = useSelector(
    (state) => state.hotelAllData
  );

  const [buyNowModelData, setbuyNowModelData] = useState({
    status: false,
    data: {},
  });

  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );

  const [searchBoxData, setsearchBoxData] = useState(searchCardData);
  const filterData = useSelector((state) => state.hotelAllData);
  const gst = filterData?.filterData[0]?.hotelLocation?.gsttax;


  const handleBook = (v) => {
    dispatch(setSelectedRoom(v));
    dispatch(setSelectedRoomRate(v));
    setbuyNowModelData({ status: true, data: v });
  };

  useEffect(() => {
    setsearchBoxData(searchCardData);
  }, []);

  useEffect(() => {
    if (buyNowModelData?.status) {
      getRoomPrice(buyNowModelData?.data);
      modelConfirm();
    }
  }, [buyNowModelData]);

  useEffect(() => {
    // dispatch(setSelectedRoomRate(dataForBilling));
    buyNowModelData?.status && nav("/hotel/checkout");
  }, [dataForBilling]);

  const getRoomPrice = (data) => {
    const checkInDate = parse(
      searchCardData?.checkIn,
      "dd/MM/yyyy",
      new Date()
    );
    const checkOutDate = parse(
      searchCardData?.checkOut,
      "dd/MM/yyyy",
      new Date()
    );

    let numberOfDays = differenceInDays(checkOutDate, checkInDate);
    let children = searchCardData?.guestDetails?.childAge.filter((val) => {
      return val > 10;
    });
    let adults = searchCardData?.guestDetails?.adults + children?.length;
    let rooms = searchCardData?.guestDetails?.rooms;
    let roomPrice = 0;
    let roomRateWithOutDiscount = 0;
    let payableAmount = 0;
    let gstAmount = 0;
    const rate = data?.roomRate;
    const commission = selectedHotelData?.commission;
    const subTotal = rate + commission;
    if (adults > rooms && adults - rooms !== 1) {
      if (adults === 3) {
        roomPrice = (subTotal + data.extraBedAdult) * numberOfDays;
      } else if (adults > 3) {
        rooms = Math.ceil(adults / 3);
        let extraAdult = adults % 3 === 0 ? rooms : rooms - 1;
        if (adults === 4) {
          roomPrice = subTotal * rooms * numberOfDays;
        } else if (rooms > data?.noOfRooms) {
          //setstopSale(true);
        } else if (Math.sqrt(adults) === searchCardData?.guestDetails?.rooms) {
          rooms = searchCardData?.guestDetails?.rooms;
          roomPrice = subTotal * rooms * numberOfDays;
        } else {
          roomPrice =
            (subTotal * rooms + data.extraBedAdult * extraAdult) * numberOfDays;
        }
      }
    } else {
      if (rooms > data?.noOfRooms) {
        //setstopSale(true);
      } else {
        roomPrice = subTotal * rooms * numberOfDays;
      }
    }
    roomRateWithOutDiscount = roomPrice * (100 / (100 - data.discountRate));
    payableAmount = roomPrice + roomPrice * (Number(gst) / 100);
    gstAmount = roomPrice * (Number(gst) / 100);

    buyNowModelData?.status &&
      setDataForBilling({
        rooms: rooms,
        adults: adults,
        roomRateWithDiscount: roomPrice,
        discountRate: data?.discountRate,
        roomRateWithOutDiscount: roomRateWithOutDiscount,
        payableAmount: payableAmount,
        gstAmount: gstAmount,
      });

    return roomPrice;
  };

  const modelConfirm = async () => {
    const roomCatId = buyNowModelData.data.roomCategoryId?._id;
    const hotelAvId = datas[0]?.hotelAvailabilityId.filter(
      (o) => o.roomCategory == roomCatId
    )[0]?._id;

    const {
      // destination,
      checkOut,
      checkIn,
      guestDetails: { adults, childAge, children, rooms },
    } = searchBoxData;

    const payload = {
      hotelId: datas[0]?._id,
      hotelAvailabilityId: hotelAvId,
      checkIn: checkIn,
      checkOut: checkOut,
      rooms: rooms,
      roomsToDisplay: rooms,
      roomCategory: roomCatId,
      roomType: buyNowModelData.data.roomTypeId?._id,
      guestDetails: {
        adults: adults,
        child: children,
        childAge: childAge,
      },
    };

    dispatch(setRoomPrice(payload));
    // setloader(true);
    try {
      const res = await hotelConfermFunction(payload);
      if (res?.contractRateRoomDTO[0]) {
        dispatch(setSelectedRoom(res.contractRateRoomDTO[0]));
      }
    } catch (err) {
      console.log(err);
    }

    //setloader(false);
  };

  return (
    <Paper
      className="hotel-Room-Card"
      elevation={3}
      style={{
        width: "100%",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Col
          span={24}
          style={{
            backgroundColor: "#B3D3F6",
            borderRadius: "10px 10px 0px 0px",
            padding: "2% 5%",
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: "15px" }}>
            {data?.roomCategoryId?.roomCategory}
          </p>
          <span style={{ fontSize: "12px", color: "#2C2C2C" }}>
            Accommodate - {data?.totalAdult} Adults,{data?.totalChild} Child
          </span>
        </Col>
      </Row>
      <Row style={{ padding: "5%" }}>
        <Col lg={16} md={16} sm={15} xs={15}>
          <p style={{ color: "#2C2C2C", fontWeight: "400" }}>
            {data.newRoomArea} Sq.ft
          </p>
          <p style={{ color: "#2C2C2C", fontWeight: "700", fontSize: "15px" }}>
            {data?.roomTypeId?.tagLine}
          </p>
          <p
            style={{
              color: "#1675DF",
              fontWeight: "300",
              fontSize: "12px",
              opacity: "85%",
              cursor: "pointer",
            }}
            // onClick={() => {
            // setCancellationreportdrower(true);
            // }}
            onClick={showModal}
          >
            Cancellation Policy
          </p>
        </Col>
        <Col lg={8} md={8} sm={9} xs={9}>
          {data?.newRoomimage ? (
            <img className="hotel-card" src={data?.newRoomimage} alt="" />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <hr className="hotel-cart-border"></hr>
        </Col>
      </Row>
      <Row style={{ padding: "5%" }}>

        <Col lg={16} md={16} sm={14} xs={14}>

          {(data?.stopSale) ? <></> : (
            <>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon
                  className="fa-rupee-sign"
                  icon={faIndianRupeeSign}
                />
                {/* {~~data.roomRate}  */}
                {~~data?.actualRoomRate1} (INR)
                <span
                  style={{
                    fontWeight: "400",
                    paddingLeft: "1%",
                    fontSize: "13px",
                  }}
                >
                  {~~data.discountRate}% Off
                </span>
              </p>
              {data?.discountRate !== undefined && (
                <p style={{ color: "red" }}>
                  <s>
                    <FontAwesomeIcon
                      className="fa-rupee-sign"
                      icon={faIndianRupeeSign}
                    />
                    {/* {Math.ceil(data?.roomRate * (100 / (100 - data?.discountRate)))} */}
                    {Math.ceil(parseInt(~~data?.roomRate1))}
                  </s>
                </p>
              )}
              <p
                style={{
                  color: "#151515",
                  fontSize: "13px",
                  fontWeight: "500",
                  fontStyle: "Roboto",
                }}
              >
                {" "}
                {data.isRefundable ? "(Refundable)" : "(Non-Refundable)"}
              </p>
            </>
          )
          }
        </Col>

        <Col lg={8} md={8} sm={10} xs={10}>

          {(data?.stopSale) ? (
            <div
              className="h4"
              style={{ color: "Red", fontSize: "1.5rem" }}
            >
              <img className="sold-out-img" src={soldout} alt="Sold Out" />
            </div>
          ) :
            (
              <Button
                className="card-button"
                onClick={() => {
                  handleBook(data);
                }}
                variant="contained"
                color="primary"
              >
                Select Room
              </Button>
            )
          }
        </Col>
      </Row>
    </Paper>
  );
};

export default HotelRoomCard;
