/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from "react";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import BottomFooter from "../../../pages/usersPage/newUserLayout/userFooter/BottomFooter";
import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { addDays } from "date-fns";
import "../../../styles/customcal.scss";

function CalenderRangePicker({ hideShowCalendarPicker, searchBoxData, setsearchBoxData }) {

  const DATE_FORMAT = "DD/MM/YYYY";

  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );

  const [state, setState] = useState([
    {
      startDate: moment(searchCardData?.checkIn, DATE_FORMAT).toDate(),
      endDate: moment(searchCardData?.checkOut, DATE_FORMAT).toDate(),
      key: 'selection',
    }
  ]);

  const onContinue = () => {

    let payload = { ...searchBoxData };

    if (state[0]?.startDate == state[0]?.endDate) {
      state[0].endDate = addDays(state[0].endDate, 1);
    }

    payload.checkIn = moment(state[0]?.startDate).format(DATE_FORMAT);
    payload.checkOut = moment(state[0]?.endDate).format(DATE_FORMAT);

    setsearchBoxData(payload);

    hideShowCalendarPicker();
  }

  const startDate = state[0].startDate;
  const endDate = state[0].endDate;
  const numberOfNights = Math.max(0, Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)));

  const onChange = (item) => {
    setState([item.selection]);
  }



  return (
    <div className="drp-container">

      <div className="row back-head">
        <div className="col-6" onClick={hideShowCalendarPicker}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#B0B0B0" className="cal-back-btn">
            <path d="M15.063 7.04H3.624a.167.167 0 01-.155-.109.166.166 0 01.045-.184l5.774-5.083A.964.964 0 008.018.217L.543 6.8a1.608 1.608 0 000 2.407l7.473 6.58a.963.963 0 001.27-1.446L3.513 9.257a.168.168 0 01.11-.293h11.44a.962.962 0 000-1.925v.001z">
            </path>
          </svg>
          <span className="select-dates">Select Dates</span>
        </div>
      </div>

      <div className="row drp-head">
        <div className="col" style={{ textAlign: 'center' }}>
          <div>Check-In</div>
          <b className="dates">{startDate.toLocaleDateString()}</b>
        </div>
        {
          <div className="col" style={{ textAlign: 'center' }}>
            {
              numberOfNights > 0 ? <div className="noof-night">{numberOfNights} {numberOfNights > 1 ? "Nights" : "Night"}</div>
                :
                <span>&nbsp;</span>
            }
          </div>
        }
        <div className="col" style={{ textAlign: 'center' }}>
          <div>Check-Out</div>
          <b className="dates">{endDate.toLocaleDateString()}</b>
        </div>
      </div>
      <DateRange
        onChange={onChange}
        showMonthAndYearPickers={false}
        minDate={new Date()}
        ranges={state}
        direction="vertical"
        scroll={{ enabled: true }}
        months={2}
        showMonthArrow={false}
        className="rdr-picker"
        rangeColors={['#0094fe']}
        dateDisplayFormat="dd/MM/yyyy"
        weekStartsOn={1}
        showDateDisplay={false}
      />
      <BottomFooter>
        <Row style={{ padding: "1%", width: "100%" }}>
          {/* <Col span={12}>
            <Button style={{ height: "110%", float: "left", backgroundColor: "#d9d9d9" }} onClick={hideShowCalendarPicker}>
              Back
            </Button>
          </Col> */}
          <Col span={24}>
            <Button type="primary" className="continuebtn" variant="solid" style={{ height: "110%", float: "right", width: "100%" }} onClick={() => onContinue()}>
              Continue
            </Button>
          </Col>
        </Row>
      </BottomFooter>
    </div>
  );
}


export default CalenderRangePicker;