/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Col, Row, Table, Tag, Grid, Tabs, Modal, Rate } from "antd";
import sfPro from "../../../../../assets/FontsFree-Net-SFProDisplay-Regular.ttf";

import couple from "../../../../../assets/couple1.png";
import assured from "../../../../../assets/gosAssured.png";
import bed from "../../../../../assets/room-bed.png";
import foopot from "../../../../../assets/foopot.png";
import drier from "../../../../../assets/hair-dryer.png";
import maplogo from "../../../../../assets/map-location.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef, useState } from "react";
import tik from "../../../../../assets/tikorg.png";
import moment from "moment/moment";
import { Helmet } from "react-helmet";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  faUser,
  faBed,
  faAngleDoubleRight,
  faStar,
  faWifi,
  faPlug,
  faUsers,
  faArrowLeft,
  faCheck,
  faFan,
  faPhone,
  faVideo,
  faHotTub,
  faShieldAlt,
  faUtensils,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BtnComp from "../../../../../components/atoms/buttonComponent/BtnComp";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import BottomFooter from "../../../newUserLayout/userFooter/BottomFooter";
import HeaderForAll from "../../../../homePage/homePageHeader/HeaderForAll";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import GoogleMapComponent from "../../../../../components/atoms/GoogleMapComponent/GoogleMapComponent";
import {
  setRoomPrice,
  setSelectedRoom,
} from "../../../../../reduxData/sliceData/hotelReducer";
import { hotelConfermFunction } from "../../../../../services/utils/userUtils/hotelutils";
import { UserLoader } from "../../../../../components/atoms/userLoader";
import HeaderSearchCard from "../../../../../components/atoms/card/userCards/HeaderSearchCard";
import { setSelectedRoomRate } from "../../../../../reduxData/sliceData/searchCardReducer";
import { differenceInDays, parse } from "date-fns";
import { SuggestionCard } from "../../../../../components/atoms/card/suggestionCard/SuggestionCard";
import HotelSeo from "../../../../../constants/seo/hotelSeo";
import InnerDrawer from "./drawer";
import ViewAll from "./viewAll";
import SelectRoom from "./selectRoom";
import soldout from "../../../../../assets/soldout.png";

const { useBreakpoint } = Grid;

const style = {
  border: "1px solid #ff4d63",
  padding: "5%",
  textAlign: "center",
  fontWeight: "700",
};

const iconObj = {
  Wifi: faWifi,
  Power: faPlug,
  bed: bed,
  foopot: foopot,
  drier: drier,
  AC: faFan,
  Telephone: faPhone,
  CCTV: faVideo,
  Spa: faHotTub,
  Restaurant: faUtensils,
  ["Security alarms"]: faShieldAlt,
};

function HotelBookNow() {
  const { sm, md, lg } = useBreakpoint();
  const [reviewdrower, setreviewdrower] = useState(false);
  const { filterData: data, data: allData } = useSelector(
    (state) => state.hotelAllData
  );

  const selectedHotelData = useSelector(
    (state) => state?.hotelAllData?.selectedData
  );

  const [searchboxShow, setsearchboxShow] = useState(false);
  useEffect(() => {
    lg || md ? setsearchboxShow(true) : setsearchboxShow(false);
  }, [lg, md]);

  const targetRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    targetRef.current?.scrollIntoView({
      behavior: "smooth",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [targetRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const divRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  const [hotelRoomCategoryObj, setHotelRoomCategoryObj] = useState([]);
  const [similarHotel, setsimilarHotel] = useState([]);
  const [loader, setloader] = useState(false);
  const dispatch = useDispatch();
  // const [userModalOpen, setUserModalOpen] = useState(false);
  const [viewAllType, setviewAllType] = useState("all");
  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );
  const gst = data[0]?.hotelLocation?.gsttax;
  const [searchBoxData, setsearchBoxData] = useState(searchCardData);
  const [stopSale, setstopSale] = useState(
    (data[0]?.hotelAvailabilityId?.reduce(
      (n, { noOfRooms }) => n + noOfRooms,
      0
    ) ?? 0) == 0 || data[0]?.stopSale
  );

  const [dataForBilling, setDataForBilling] = useState({
    rooms: 0,
    roomRateWithDiscount: 0,
    roomRateWithOutDiscount: 0,
    discountRate: 0,
    gstAmount: 0,
    payableAmount: 0,
    adults: 0,
    childrens: 0,
  });

  const [buyNowModelData, setbuyNowModelData] = useState({
    status: false,
    data: {},
  });

  useEffect(() => {
    setsearchBoxData(searchCardData);
  }, []);

  const HotelRoomCategoryObjFun = () => {
    let newHotelRoomCategoryObjFun = _.map(
      data[0]?.hotelContractId.contractRateRoomDTO,
      (u) => {
        let obj = { ...u };
        _.forEach(data[0]?.hotelRoomCategory, (v) => {
          if (u.roomCategoryId?._id === v.roomCategory?._id) {
            obj.newRoomimage = v?.roomImages && v?.roomImages[0];
            obj.newRoomArea = v?.roomCategory?.roomArea;
          }
        });

        _.forEach(data[0]?.hotelContractId.refundableObj, (v) => {
          if (u.roomCategoryId?._id === v.roomCategoryId?._id) {
            obj.isRefundable = v?.isRefundable;
          }
        });
        _.forEach(data[0]?.hotelAvailabilityId, (v) => {
          if (u.roomCategoryId?._id === v?.roomCategory) {
            obj.noOfRooms = v?.noOfRooms;
          }
        });
        obj.profilePic = data[0]?.profilePic;
        return obj;
      }
    );
    setHotelRoomCategoryObj(newHotelRoomCategoryObjFun);
  };

  const [more, setmore] = useState(false);
  const [vroom, setvroom] = useState(false);
  const nav = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const sliderRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 2, // Show one slide on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 369, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingshotels = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show one slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsDesktop(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBook = (v) => {
    dispatch(setSelectedRoom(v));
    dispatch(setSelectedRoomRate(v));
    // dispatch(selectHotel(v));
    setbuyNowModelData({ ...buyNowModelData, status: true, data: v });
  };
  useEffect(() => {
    if (buyNowModelData?.status) {
      getRoomPrice(buyNowModelData?.data);
      modelConfirm();
    }
  }, [buyNowModelData]);

  useEffect(() => {
    // dispatch(setSelectedRoomRate(dataForBilling));
    buyNowModelData?.status && nav("/hotel/checkout");
  }, [dataForBilling]);

  const checkInDate = parse(searchCardData?.checkIn, "dd/MM/yyyy", new Date());
  const checkOutDate = parse(
    searchCardData?.checkOut,
    "dd/MM/yyyy",
    new Date()
  );

  const getRoomPrice = (data) => {
    const checkInDate = parse(
      searchCardData?.checkIn,
      "dd/MM/yyyy",
      new Date()
    );
    const checkOutDate = parse(
      searchCardData?.checkOut,
      "dd/MM/yyyy",
      new Date()
    );

    let numberOfDays = differenceInDays(checkOutDate, checkInDate);

    let children = searchCardData?.guestDetails?.childAge.filter((val) => {
      return val > 10;
    });
    let adults = searchCardData?.guestDetails?.adults + children?.length;
    let rooms = searchCardData?.guestDetails?.rooms;

    let roomPrice = 0;
    let roomRateWithOutDiscount = 0;
    let payableAmount = 0;
    let gstAmount = 0;
    const rate = data?.roomRate;
    const commission = selectedHotelData?.commission;
    const subTotal = rate + commission;
    if (adults > rooms && adults - rooms !== 1) {
      if (adults === 3) {
        roomPrice = (subTotal + data?.extraBedAdult) * numberOfDays;
      } else if (adults > 3) {
        rooms = Math.ceil(adults / 3);
        let extraAdult = adults % 3 === 0 ? rooms : rooms - 1;
        if (adults === 4) {
          roomPrice = subTotal * rooms * numberOfDays;
        } else if (rooms > data?.noOfRooms) {
          //setstopSale(true);
        } else if (Math.sqrt(adults) === searchCardData?.guestDetails?.rooms) {
          rooms = searchCardData?.guestDetails?.rooms;
          roomPrice = subTotal * rooms * numberOfDays;
        } else {
          roomPrice =
            (subTotal * rooms + data?.extraBedAdult * extraAdult) *
            numberOfDays;
        }
      }
    } else {
      if (rooms > data?.noOfRooms) {
        //setstopSale(true);
      } else {
        roomPrice = subTotal * rooms * numberOfDays;
      }
    }
    roomRateWithOutDiscount = roomPrice * (100 / (100 - data?.discountRate));
    payableAmount = roomPrice + roomPrice * (Number(gst) / 100);
    gstAmount = roomPrice * (Number(gst) / 100);
    buyNowModelData?.status &&
      setDataForBilling({
        rooms: rooms,
        adults: adults,
        roomRateWithDiscount: roomPrice,
        discountRate: data?.discountRate,
        roomRateWithOutDiscount: roomRateWithOutDiscount,
        payableAmount: payableAmount,
        gstAmount: gstAmount,
      });
    return roomPrice;
  };

  let numberOfNights = differenceInDays(checkOutDate, checkInDate);
  let noOfRoom = searchCardData?.guestDetails?.rooms;
  const newDataSource = hotelRoomCategoryObj
    .sort(({ roomRate: a }, { roomRate: b }) => a - b)
    .map((v, i) => {
      return {
        key: i,
        RoomCategory: (
          <div key={i} style={{ textAlign: "left" }}>
            <div>
              <p className="fw-300 fs-5">{v?.roomCategoryId?.roomCategory}</p>
              <p
                className="fs-7 fw-400 color-b1"
                style={{
                  color: "#1675DF",
                  fontWeight: "300",
                  fontSize: "12px",
                  opacity: "85%",
                  cursor: "pointer",
                }}
                onClick={showModal}
              >
                Cancellation policy
              </p>
              <p className="color-b1 fw-700">
                <FontAwesomeIcon icon={faUsers} />
                Accomadates-{v.totalAdult} Adult,{v.totalChild} Child
              </p>
              {/* <p className="">200</p> */}
              <p className="fw-medium" style={{ textAlign: "center" }}>
                {v.newRoomArea}Sq.ft.
              </p>
              <img
                alt=""
                style={{ width: "80%", margin: "auto", display: "block" }}
                // src="https://www.godsownstay.com/files/discoverytravel/profilepic/hotel/30_12_2021_16408499436_hotel.jpg"
                src={v?.newRoomimage}
              />
            </div>
          </div>
        ),
        ShareType: (
          <>
            <div style={{ textAlign: "left" }}>
              <div key={i}>
                <p className="fs-7 fw-400 color-b1">{v?.roomTypeId?.tagLine}</p>
              </div>
            </div>
          </>
        ),
        Price: (
          <>
            <div style={{ textAlign: "left" }}>
              {v?.stopSale ? (
                <div> Not Available </div>
              ) : (
                <div key={i}>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#cc0000",
                      fontWeight: "bold",
                    }}
                  >
                    {v?.actualRoomRate1}
                    {" INR"}
                  </span>
                  <s
                    className=" p-1"
                    style={{
                      color: "#888",
                      fontSize: "0.7rem",
                      textDecoration: "line-through",
                      textDecorationColor: "red",
                      textDecorationThickness: "2px",
                    }}
                  >
                    {Math.ceil(v?.roomRate1) >= 0 ? Math.ceil(v?.roomRate1) : 0}

                    {" INR"}
                  </s>
                  <span
                    className=""
                    style={{ color: "#ccc", fontSize: "0.7rem" }}
                  >
                    {~~v.discount1}% off
                  </span>
                  <p style={{ color: "#666", marginTop: "0px" }}>
                    {v.isRefundable ? "(Refundable)" : "(Non-Refundable)"}
                  </p>
                </div>
              )}
            </div>
          </>
        ),
        Book: v?.stopSale ? (
          <div
            span={8}
            className="ps-2 h4"
            style={{ color: "Red", fontSize: "1.5rem" }}
          >
            <img className="sold-out-img" src={soldout} alt="Sold Out" />
          </div>
        ) : (
          <Button
            style={{
              margin: "0%",
              color: "#fff",
              width: "100%",
              borderRadius: "3px",
              cursor: "pointer",
              backgroundColor: "#3d75df",
            }}
            // disabled={stopSale}
            onClick={() => {
              handleBook(v);
            }}
          >
            <span style={{ cursor: "pointer" }}> Book Now</span>
          </Button>
        ),
      };
    });

  const modelConfirm = async () => {
    const roomCatId = buyNowModelData?.data?.roomCategoryId?._id;
    const hotelAvId = data[0]?.hotelAvailabilityId.filter(
      (o) => o.roomCategory == roomCatId
    )[0]?._id;

    const {
      // destination,
      checkOut,
      checkIn,
      guestDetails: { adults, childAge, children, rooms },
    } = searchBoxData;

    const payload = {
      hotelId: data[0]?._id,
      hotelAvailabilityId: hotelAvId,
      checkIn: checkIn,
      checkOut: checkOut,
      rooms: rooms,
      roomsToDisplay: rooms,
      roomCategory: roomCatId,
      roomType: buyNowModelData?.data?.roomTypeId?._id,
      guestDetails: {
        adults: adults,
        child: children,
        childAge: childAge,
      },
    };

    dispatch(setRoomPrice(payload));
    setloader(true);
    try {
      const res = await hotelConfermFunction(payload);
      if (res?.contractRateRoomDTO[0]) {
        dispatch(setSelectedRoom(res.contractRateRoomDTO[0]));
      }
    } catch (err) {
      console.log(err);
    }

    setloader(false);
  };

  useEffect(() => {
    HotelRoomCategoryObjFun();
  }, [data]);

  useEffect(() => {
    const newHotelList =
      Array.isArray(allData) &&
      allData?.filter(async (v) => {
        const dis = getDistance(
          data[0]?.hotelLocation.latitude,
          data[0]?.hotelLocation.longitude,
          v.hotelLocation.latitude,
          v.hotelLocation.longitude
        );
        return dis <= 5;
      });
    setsimilarHotel(newHotelList);
    scrollToTop();
    return () => scrollToTop();
  }, []);

  const scrollToTop = () => {
    document
      .getElementById("hotelNameHead")
      ?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <>
      {loader ? <UserLoader /> : ""}
      {isDesktop && <HeaderSearchCard ref={targetRef} />}
      {currentPage === 1 ? (
        <>
          <div>
            <div
              className="mobile2ndHeadGos"
              // onClick={handlePrevClick}
              style={{
                display: `${!sm || !md ? "block" : "none"}`,
                maxHeight: "100px",
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} onClick={() => nav(-1)} />{" "}
              &nbsp;
              <span className="fs-5 fw-medium">
                {data[0]?.basicDetails?.hotelName}
              </span>
              <Row style={{ marginTop: "-10px", paddingLeft: "32px" }}>
                <p
                  className="pe-1"
                  style={{
                    fontWeight: "400",
                  }}
                  onClick={() => setsearchboxShow((prevState) => !prevState)}
                >
                  {moment(searchCardData?.checkIn, "DD/MM/YYYY").format(
                    "Do MMM"
                  )}{" "}
                  -{" "}
                  {moment(searchCardData?.checkOut, "DD/MM/YYYY").format(
                    "Do MMM"
                  )}
                  , Adult {searchCardData?.guestDetails.adults}{" "}
                </p>

                <Button
                  className=""
                  onClick={() => {
                    setsearchboxShow((prevState) => !prevState);
                  }}
                  type="primary"
                  size="small"
                  style={{ backgroundColor: "#1675df" }}
                  // closeIcon={<DownOutlined style={{ color: "white" }} />}
                >
                  Edit&nbsp;
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    flip={searchboxShow ? "vertical" : "horizontal"}
                  />
                </Button>
              </Row>
            </div>
            {isMobile && searchboxShow && (
              <HeaderSearchCard showSearch={searchboxShow} />
            )}
            <Paper
              style={{
                margin: "0px",
                padding: "0px",
                position: `${lg || md ? "relative" : "sticky"}`,
                top: "0",
                zIndex: "1",
                overflowY: "scroll",
              }}
            >
              <div style={{ position: "relative" }}>
                <div className="mobile-pic-slider">
                  <Slider
                    className="chenmayslider"
                    {...settings}
                    ref={sliderRef}
                    arrows={false}
                    style={{ width: "100%", position: "relative" }}
                  >
                    {data[0]?.profilePic?.map((val, idx) => {
                      return (
                        // <div >
                        <Col
                          span={24}
                          key={idx}
                          className={`profilePic-${idx}`}
                        >
                          <span className="slick-card-buyNow">
                            <img
                              src={val}
                              alt={`profilePic-${idx}`}
                              className="slick-card-img"
                              style={{
                                height: `${!sm || !md ? "150px" : "300px"}`,
                              }}
                            />
                          </span>
                        </Col>
                      );
                    })}
                  </Slider>
                </div>
                <div className="smallCarousel mobile-view">
                  {/* {!md ?} */}
                  {md &&
                    data[0]?.profilePic?.map((val, id) => {
                      return (
                        <div
                          className="slick-card-buyNow-nb"
                          style={{ height: "90px", width: "150px" }}
                          key={id}
                        >
                          <img
                            src={val}
                            style={{ height: "100%", width: "100%" }}
                            className=""
                            alt=""
                            // onClick={()=>setImageNo(i)}
                          />
                        </div>
                      );
                    })}
                  <div
                    className="slick-card-buyNow-nb"
                    style={{ height: "90px", width: "150px" }}
                  >
                    <img
                      src={data[0]?.profilePic[0]}
                      style={{ height: "100%", width: "100%" }}
                      className=""
                      alt=""
                      // onClick={()=>setImageNo(i)}
                    />
                  </div>
                  {data[0]?.profilePic[1] !== undefined && (
                    <div
                      className="slick-card-buyNow-nb"
                      style={{ height: "90px", width: "150px" }}
                    >
                      <img
                        src={data[0]?.profilePic[1]}
                        style={{ height: "100%", width: "100%" }}
                        className=""
                        alt=""
                        // onClick={()=>setImageNo(i)}
                      />
                    </div>
                  )}
                  {/* {sm && ( */}
                  <div
                    className="slick-card-buyNow-nb view-all-btn"
                    style={{ height: "90px", width: "150px" }}
                    onClick={() => {
                      if (!md) {
                        setCurrentPage(3);
                        setTimeout(() => {
                          if (divRef.current) {
                            divRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }, 1000);
                      }
                    }}
                  >
                    <button className="viewAll"> View All</button>
                  </div>
                </div>
              </div>
            </Paper>
            <div
              className="bookNowTab"
              style={{
                display: `${lg || md ? "" : "none"}`,
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "#fff",
                overflowY: "scroll",
              }}
            >
              <Tabs
                defaultActiveKey="1"
                tabBarStyle={{
                  boxShadow: "6px 6px 12px #b3b3b3-6px -6px 12px #ffffff",
                  width: "100%",
                }}
                items={[
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#description">
                        Description
                      </a>
                    ),
                    key: 1,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#amenities">
                        Amenities
                      </a>
                    ),
                    key: 2,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#rating">
                        Hotel Rating & Review
                      </a>
                    ),
                    key: 3,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#vroom">
                        Room Category
                      </a>
                    ),
                    key: 4,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#policies">
                        Hotel Policies
                      </a>
                    ),
                    key: 5,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#terms">
                        Terms and Conditions
                      </a>
                    ),
                    key: 6,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#nearby">
                        Near By
                      </a>
                    ),
                    key: 7,
                    children: <></>,
                  },
                  {
                    label: (
                      <a style={{ textDecoration: "none" }} href="#location">
                        Location
                      </a>
                    ),
                    key: 8,
                    children: <></>,
                  },
                ]}
              />
            </div>

            <Row>
              <Col lg={16} md={16} sm={24} xs={24} id="hotel-details">
                <div className="buynowhead-bix">
                  {HotelSeo.map((v, i) => {
                    if (
                      v.hotelName ==
                      data[0]?.basicDetails?.hotelName.toLowerCase()
                    ) {
                      return (
                        <Helmet key={i}>
                          <title>{v.title}</title>
                          <meta name="description" content={v.description} />
                        </Helmet>
                      );
                    }
                  })}
                  <p id="hotelNameHead" className="hotelName">
                    {data[0]?.basicDetails?.hotelName}
                  </p>
                  <p className="address" style={{ color: "#888" }}>
                    {data[0]?.hotelLocation.hotelAddress}
                  </p>
                  <Row className="bix-btn w-100" justify={"space-evenly"}>
                    <Col
                      span={12}
                      style={{
                        borderRight: "1px solid #ccc",
                        paddingRight: "2%",
                      }}
                    >
                      {data[0]?.basicDetails?.coupleFriendlyHotel ? (
                        <div>
                          {" "}
                          <img
                            style={{ height: "25px", width: "25px" }}
                            src={couple}
                            alt=""
                          />
                          &nbsp;{" "}
                          <span className="address">Couple Friendly</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </Col>
                    <Col span={12}>
                      {data[0]?.basicDetails?.sanitizedStay ? (
                        <div>
                          {" "}
                          <img
                            style={{ height: "25px", width: "25px" }}
                            // src="https://static.thenounproject.com/png/1716003-200.png"
                            src={assured}
                            alt=""
                          />
                          &nbsp;{" "}
                          <span className="address">Gos Stay Assured </span>
                        </div>
                      ) : (
                        " "
                      )}
                    </Col>
                  </Row>
                  <Row className="p-2 pr-4 my-2 ">
                    {" "}
                    <Tag
                      color="#52b520"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "1.5px 6px",
                      }}
                    >
                      {/* {data[0]?.hotelReviewId?.rating}{" "} */}
                      <span style={{ fontSize: "15px" }}>
                        {data[0]?.hotelReviewId?.length > 0
                          ? // Calculate the average rating
                            (
                              data[0]?.hotelReviewId?.reduce(
                                (sum, review) => sum + review.rating,
                                0
                              ) / data[0]?.hotelReviewId?.length
                            ).toFixed(1)
                          : 0}{" "}
                      </span>
                      &nbsp;
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ fontSize: "11px", lineHeight: "14px" }}
                      />
                    </Tag>
                  </Row>
                  <div id="description" className="bix-box">
                    <h6>Description</h6>
                    <p className="address">{data[0]?.hotelDescription}</p>
                  </div>

                  <h3 className="scroll-to-content mt-3" id="amenities">
                    {" "}
                    Amenities{" "}
                  </h3>
                  <Row className="bix-btn-gray mb-4" justify={"space-evenly"}>
                    {data[0]?.hotelAmenities &&
                      data[0]?.hotelAmenities
                        ?.slice(0, 8)
                        .map((amenityName, id) => {
                          return (
                            <Col span={5} className="bix-btn-br m-2" key={id}>
                              {iconObj[amenityName?.amenityName] ? (
                                <FontAwesomeIcon
                                  icon={iconObj[amenityName?.amenityName]}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              &nbsp;{amenityName.amenityName}{" "}
                            </Col>
                          );
                        })}
                  </Row>
                  {data[0]?.hotelAmenities?.length > 8 ? (
                    <p
                      onClick={() => setmore((o) => !o)}
                      style={{
                        color: "#8a2be2",
                        paddingTop: "1%",
                        paddingBottom: "3%",
                      }}
                    >
                      {more ? "hide" : "+ more"}
                    </p>
                  ) : (
                    ""
                  )}
                  {more && (
                    <Row
                      className="bix-btn-gray mb-4 mt-0"
                      justify={"space-evenly"}
                    >
                      {data[0]?.hotelAmenities
                        ?.slice(8)
                        .map((amenityName, id) => {
                          return (
                            <Col span={6} className="bix-btn-br" key={id}>
                              {iconObj[amenityName?.amenityName] ? (
                                <FontAwesomeIcon
                                  icon={iconObj[amenityName?.amenityName]}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              &nbsp;{amenityName.amenityName}{" "}
                            </Col>
                          );
                        })}
                    </Row>
                    // </Row>
                  )}

                  <div className="bix-box mb-5">
                    <h5 className="scroll-to-content" id="rating">
                      Hotel Rating
                    </h5>
                    <Row gutter={[10, 20]}>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <p style={{ color: "#888" }}>Customer Review</p>
                        <span
                          className="fs-5"
                          style={{ color: "#52b520", fontWeight: "bold" }}
                        >
                          {data[0]?.hotelReviewId?.length > 0
                            ? (
                                data[0]?.hotelReviewId?.reduce(
                                  (sum, review) => sum + review.rating,
                                  0
                                ) / data[0]?.hotelReviewId?.length
                              ).toFixed(1)
                            : 0}
                        </span>
                        <Row gutter={10}>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={
                              data[0]?.hotelReviewId?.length > 0
                                ? // Calculate the average rating
                                  (
                                    data[0]?.hotelReviewId?.reduce(
                                      (sum, review) => sum + review.rating,
                                      0
                                    ) / data[0]?.hotelReviewId?.length
                                  ).toFixed(1)
                                : 0
                            }
                            style={{ color: "#52b520" }}
                          />
                        </Row>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                        <p style={{ color: "#888" }}> Rating breakdown</p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {" "}
                          5 &#9733;
                          <div
                            className="progress"
                            style={{
                              height: "9px",
                              width: "80%",
                              margin: " 0px 3%",
                            }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${
                                  (data[0]?.hotelReviewId?.reduce(
                                    (total, review) => {
                                      if (review?.rating === 5) {
                                        return total + 1;
                                      } else {
                                        return total;
                                      }
                                    },
                                    0
                                  ) *
                                    100) /
                                  data[0]?.hotelReviewId?.length
                                }%`,
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          {data[0]?.hotelReviewId?.reduce((total, review) => {
                            // If the rating is 5, increment the total count
                            if (review?.rating === 5) {
                              return total + 1;
                            } else {
                              return total; // No change to the total count
                            }
                          }, 0)}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {" "}
                          4 &#9733;
                          <div
                            className="progress"
                            style={{
                              height: "9px",
                              width: "80%",
                              margin: " 0px 3%",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{
                                width: `${
                                  (data[0]?.hotelReviewId?.reduce(
                                    (total, review) => {
                                      if (review?.rating === 4) {
                                        return total + 1;
                                      } else {
                                        return total;
                                      }
                                    },
                                    0
                                  ) *
                                    100) /
                                  data[0]?.hotelReviewId?.length
                                }%`,
                              }}
                              aria-valuenow="80"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          {data[0]?.hotelReviewId?.reduce((total, review) => {
                            // If the rating is 5, increment the total count
                            if (review?.rating === 4) {
                              return total + 1;
                            } else {
                              return total; // No change to the total count
                            }
                          }, 0)}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {" "}
                          3 &#9733;
                          <div
                            className="progress"
                            style={{
                              height: "9px",
                              width: "80%",
                              margin: " 0px 3%",
                            }}
                          >
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{
                                width: `${
                                  (data[0]?.hotelReviewId?.reduce(
                                    (total, review) => {
                                      if (review?.rating === 3) {
                                        return total + 1;
                                      } else {
                                        return total;
                                      }
                                    },
                                    0
                                  ) *
                                    100) /
                                  data[0]?.hotelReviewId?.length
                                }%`,
                              }}
                              aria-valuenow="60"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          {data[0]?.hotelReviewId?.reduce((total, review) => {
                            if (review?.rating === 3) {
                              return total + 1;
                            } else {
                              return total;
                            }
                          }, 0)}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {" "}
                          2 &#9733;
                          <div
                            className="progress"
                            style={{
                              height: "9px",
                              width: "80%",
                              margin: " 0px 3%",
                            }}
                          >
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{
                                width: `${
                                  (data[0]?.hotelReviewId?.reduce(
                                    (total, review) => {
                                      if (review?.rating === 2) {
                                        return total + 1;
                                      } else {
                                        return total;
                                      }
                                    },
                                    0
                                  ) *
                                    100) /
                                  data[0]?.hotelReviewId?.length
                                }%`,
                              }}
                              aria-valuenow="40"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          {data[0]?.hotelReviewId?.reduce((total, review) => {
                            // If the rating is 5, increment the total count
                            if (review?.rating === 2) {
                              return total + 1;
                            } else {
                              return total; // No change to the total count
                            }
                          }, 0)}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",
                          }}
                        >
                          {" "}
                          1 &#9733;
                          <div
                            className="progress"
                            style={{
                              height: "9px",
                              width: "80%",
                              margin: " 0px 3%",
                            }}
                          >
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${
                                  (data[0]?.hotelReviewId?.reduce(
                                    (total, review) => {
                                      if (review?.rating === 1) {
                                        return total + 1;
                                      } else {
                                        return total;
                                      }
                                    },
                                    0
                                  ) *
                                    100) /
                                  data[0]?.hotelReviewId?.length
                                }%`,
                              }}
                              aria-valuenow="20"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          {data[0]?.hotelReviewId?.reduce((total, review) => {
                            // If the rating is 5, increment the total count
                            if (review?.rating === 1) {
                              return total + 1;
                            } else {
                              return total; // No change to the total count
                            }
                          }, 0)}
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        color: "#e02d70",
                        // padding: "2%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => setreviewdrower(true)}
                    >
                      {" "}
                      <ExclamationCircleOutlined />{" "}
                      <span
                        className="px-2 fw-700 address"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        Show reviews
                      </span>
                    </div>
                  </div>

                  {/* <Row gutter={20}> */}
                  <h3>Compare to similar Hotels</h3>
                  <div
                    style={{ paddingBottom: "4%" }}
                    // className="divScrollRow"
                  >
                    <Slider
                      arrows={false}
                      {...settingshotels}
                      ref={sliderRef}
                      style={{ width: "100%", position: "relative" }}
                    >
                      {similarHotel?.length > 0 &&
                        similarHotel
                          ?.filter((v) => v?._id !== data[0]?._id)
                          .map((o, i) => {
                            return (
                              <Col key={i} span={24} className="my-2">
                                <SuggestionCard
                                  data={o}
                                  scrollToTop={scrollToTop}
                                />
                              </Col>
                            );
                          })}
                    </Slider>
                  </div>
                  {/* </Row> */}

                  {lg && (
                    <>
                      <h3> Choose your room </h3>
                      <div
                        id="vroom"
                        className="bix-box mb-5 scroll-to-content-room-category"
                        style={{
                          backgroundColor: "#fff",
                          borderColor: `${vroom ? "red" : "#eee"}`,
                        }}
                      >
                        {/* _________________________________________________________________________________________ */}
                        <Table
                          className="userTable-bn"
                          style={{
                            display: `${!sm || !md ? "none" : "block"}`,
                          }}
                          columns={[
                            {
                              title: (
                                <div
                                  style={{
                                    width: "100px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Room Category
                                </div>
                              ),
                              dataIndex: "RoomCategory",
                              key: "RoomCategory",
                            },
                            {
                              title: (
                                <div
                                  style={{
                                    width: "100px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Share Type
                                </div>
                              ),
                              dataIndex: "ShareType",
                              key: "ShareType",
                            },
                            {
                              title: (
                                <div
                                  style={{
                                    width: "180px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Price
                                </div>
                              ),
                              dataIndex: "Price",
                              key: "Price",
                            },
                            {
                              title: (
                                <div
                                  style={{
                                    width: "120px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Book
                                </div>
                              ),
                              dataIndex: "Book",
                              key: "Book",
                            },
                          ]}
                          dataSource={newDataSource}
                          pagination={false}
                          bordered
                        />
                        {/* _____________________________________________________________________________ */}
                      </div>
                      <Modal
                        title="Cancellation Policy"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        centered
                      >
                        <p
                          style={{ fontSize: "16px", textAlign: "left" }}
                          className="address"
                        >
                          <ul style={{ listStyleImage: `url(${tik})` }}>
                            {selectedHotelData?.hotelPolicyId?.CancellationPolicy.map(
                              (policy, i) => {
                                return (
                                  policy.CancellationFee > 0 && (
                                    <li key={"h" + i}>
                                      Cancellation fee of{" "}
                                      {policy.CancellationFee} % of the total
                                      booking if cancelled less than{" "}
                                      {policy.priorHour} hours prior to arrival
                                    </li>
                                  )
                                );
                              }
                            )}
                            {selectedHotelData?.hotelPolicyId?.ChildPolicy.map(
                              (policy, i) => {
                                return <li key={"c" + i}>{policy.policy}</li>;
                              }
                            )}
                          </ul>
                        </p>
                      </Modal>
                    </>
                  )}

                  <h3 className="scroll-to-content" id="policies">
                    Hotel policies
                  </h3>
                  <div>
                    <Row style={{ width: "100%" }} justify="space-between">
                      <Col span={11} className="booknow-timebtn">
                        <strong style={{ fontSize: "16px" }}>
                          {" "}
                          Check-in : 12:00 PM
                        </strong>
                      </Col>
                      <Col span={11} className="booknow-timebtn">
                        <strong style={{ fontSize: "16px" }}>
                          {" "}
                          Check-out : 11:00 AM
                        </strong>
                      </Col>
                    </Row>
                    <div className="booknowList">
                      <ul style={{ listStyleImage: `url(${tik})` }}>
                        {selectedHotelData?.hotelPolicyId?.CancellationPolicy.map(
                          (policy, i) => {
                            return (
                              policy.CancellationFee > 0 && (
                                <li key={"h" + i}>
                                  Cancellation fee of {policy.CancellationFee} %
                                  of the total booking if cancelled less than{" "}
                                  {policy.priorHour} hours prior to arrival
                                </li>
                              )
                            );
                          }
                        )}
                        {selectedHotelData?.hotelPolicyId?.ChildPolicy.map(
                          (policy, i) => {
                            return <li key={"co" + i}>{policy.policy}</li>;
                          }
                        )}
                      </ul>
                    </div>
                    <h3 id="terms" className="scroll-to-content">
                      Terms and Conditions
                    </h3>
                    <div className="booknowList">
                      <ul style={{ listStyleImage: `url(${tik})` }}>
                        {/* <li style={{ listStyleImage: `url(${tik})` }} > */}
                        {data[0]?.termsAndConditions?.map((v, i) => {
                          return <li key={i}>{v}</li>;
                        })}
                        {/* </li> */}
                      </ul>
                    </div>
                    <h3 className="scroll-to-content" id="nearby">
                      <img
                        src={maplogo}
                        style={{ width: "50px", marginRight: "1%" }}
                        alt=""
                      />
                      What&apos;s NearBy ?
                    </h3>
                    <div className="booknowList1">
                      <ul style={{ listStyleImage: `url(${tik})` }}>
                        {data[0]?.nearByAttraction?.map((v, i) => {
                          return (
                            <li key={i}>
                              {v.place}
                              {" - "}
                              {v.kilometers}
                              {" Km"}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <Row
                  justify={"start"}
                  id="location"
                  className="buynowhead-bix scroll-to-content"
                >
                  <Col lg={20} md={20} sm={20} xs={20}>
                    <GoogleMapComponent
                      location={{
                        lat: data[0]?.hotelLocation?.latitude,
                        lng: data[0]?.hotelLocation?.longitude,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                lg={8}
                md={8}
                style={{ display: `${!sm || !md ? "none" : "block"}` }}
              >
                <div className="buynow-c1">
                  <div className="buynow-c2">
                    <p className="h5 text-center pt-3">Price starts at</p>
                    <Row
                      className="pricerow"
                      gutter={[20, 5]}
                      style={{ padding: "8%" }}
                      justify="center"
                    >
                      <Col span={12}>
                        <div style={style}>
                          {selectedHotelData?.mainroomPriceWithDiscount &&
                          noOfRoom &&
                          numberOfNights ? (
                            <span>
                              &#8377;{" "}
                              {selectedHotelData?.mainroomPriceWithDiscount /
                                (noOfRoom * numberOfNights)}
                            </span>
                          ) : (
                            ""
                          )}
                          <strike style={{ color: "red" }}>
                            <span className=" p-1">
                              {selectedHotelData?.mainroomPriceWithoutDiscount &&
                              noOfRoom &&
                              numberOfNights ? (
                                <span>
                                  {" "}
                                  ₹{" "}
                                  {Math.ceil(
                                    selectedHotelData?.mainroomPriceWithoutDiscount /
                                      (noOfRoom * numberOfNights)
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </strike>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div style={style}>
                          <FontAwesomeIcon icon={faUser} color="gray" />{" "}
                          {
                            data[0]?.occupancyId?.occupencyDetails[0]
                              ?.totalAdult
                          }{" "}
                          Adult,{" "}
                          {
                            data[0]?.occupancyId?.occupencyDetails[0]
                              ?.totalChild
                          }{" "}
                          Child
                        </div>
                      </Col>
                      <Col span={12}>
                        <div style={style}>
                          &nbsp;
                          {
                            data[0]?.hotelContractId?.contractRateRoomDTO[0]
                              ?.discountRate
                          }
                          &#37; &nbsp;OFF
                        </div>
                        <p style={{ fontWeight: "700" }}>per room / night</p>
                      </Col>
                      <Col span={12}>
                        <div style={style}>
                          <FontAwesomeIcon icon={faBed} color="gray" /> 1 x
                          Rooms
                        </div>
                      </Col>
                      <Col span={20} className="viewroom">
                        <a href="#vroom">
                          <BtnComp
                            btnName="View Room Option"
                            href="#vroom"
                            btnClickFun={() => {
                              setvroom(true);
                            }}
                            className="buynow-view"
                            bg_color={"#ff4d63"}
                            pr={"10px"}
                            endIcon={faAngleDoubleRight}
                          />
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <BottomFooter>
            <Row style={{ padding: "2% 6%", width: "100%" }}>
              <Col span={16}>
                {!isNaN(selectedHotelData?.mainroomPriceWithDiscount) && (
                  <span className="h1" style={{ fontWeight: "700" }}>
                    ₹{" "}
                    {!stopSale &&
                      parseInt(selectedHotelData?.mainroomPriceWithDiscount)}
                  </span>
                )}
                &nbsp;
                {data[0]?.hotelContractId?.contractRateRoomDTO[0]
                  ?.discountRate !== undefined && (
                  <strike style={{ color: "red" }}>
                    {!isNaN(
                      Math.ceil(selectedHotelData?.mainroomPriceWithoutDiscount)
                    ) && (
                      <span
                        className=" p-1"
                        style={{
                          color: "#888",
                          fontSize: "1.1rem",
                          fontFamily: "SF Pro, Arial, sans-serif",
                          src: `${sfPro}`,
                        }}
                      >
                        ₹{" "}
                        {Math.ceil(
                          selectedHotelData?.mainroomPriceWithoutDiscount
                        )}
                      </span>
                    )}
                  </strike>
                )}
                {selectedHotelData?.mainDiscountRate >= 0 && (
                  <span style={{ color: "#ff6600" }}>
                    {~~selectedHotelData?.mainDiscountRate}% off
                  </span>
                )}
              </Col>
              {selectedHotelData?.stopSale && (
                <Col
                  span={8}
                  className="h4"
                  style={{ color: "Red", fontSize: "1.5rem" }}
                >
                  <img className="sold-out-img" src={soldout} alt="Sold Out" />
                </Col>
              )}
              {!selectedHotelData?.stopSale && (
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{ height: "120%", float: "right" }}
                    onClick={() => {
                      setCurrentPage(2);
                    }}
                  >
                    Select Room
                  </Button>
                </Col>
              )}
            </Row>
          </BottomFooter>
        </>
      ) : currentPage === 2 ? (
        <SelectRoom
          setCurrentPage={setCurrentPage}
          setsearchboxShow={setsearchboxShow}
          selectedHotelData={selectedHotelData}
          setstopSale={setstopSale}
          hotelRoomCategoryObj={hotelRoomCategoryObj}
          setloader={setloader}
          searchBoxData={searchBoxData}
        />
      ) : (
        <>
          <HeaderForAll />
          <div
            onClick={() => {
              setCurrentPage(1);
            }}
            // ref={divRef}
            id="hotel-images"
            className="mobile2ndHeadGos mb-2"
            style={{ display: `${!sm || !md ? "block" : "none"}` }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Hotel Images
          </div>
          <div className="divScrollRow p-3">
            <span
              className={
                viewAllType === "all" ? "mobile-bedge bactive" : "mobile-bedge"
              }
              onClick={() => setviewAllType("all")}
            >
              All &#40;{data[0]?.profilePic?.length}&#41;
            </span>
            <span
              className={
                viewAllType === "rooms"
                  ? "mobile-bedge bactive"
                  : "mobile-bedge"
              }
              onClick={() => setviewAllType("rooms")}
            >
              Rooms&#40;{data[0]?.hotelRoomCategory?.length}&#41;
            </span>
            <span
              className={
                viewAllType === "nearbyattraction"
                  ? "mobile-bedge bactive"
                  : "mobile-bedge"
              }
              onClick={() => setviewAllType("nearbyattraction")}
            >
              Near by attractions &#40;{data[0]?.nearByAttractions?.length}
              &#41;
            </span>
          </div>

          <ViewAll data={data} viewAllType={viewAllType} />
        </>
      )}

      <InnerDrawer
        data={data}
        isDesktop={isDesktop}
        setCurrentPage={(val) => setCurrentPage(val)}
        reviewdrower={reviewdrower}
        setreviewdrower={(val) => setreviewdrower(val)}
      />
    </>
  );
}

export default HotelBookNow;
