/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  faMapMarkerAlt,
  faBuildingUn,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import SelectComponent from "./../../SelectComponent/SelectComponent";
import { useSelector } from "react-redux";

function SearchInput({ setsearchValue, state, destinationError, setsearchBoxData, loading, dropdownOptionsArray }) {

  const locationIcon = (
    <FontAwesomeIcon style={{ marginRight: "5px" }} color={"#1675DF"} icon={faMapMarkerAlt} />
  );

  const buildingIcon = (
    <FontAwesomeIcon style={{ marginRight: "5px" }} color={"#1675DF"} icon={faBuildingUn} />
  );

  const searchIcon = (
    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: "5px" }} color={"#1675DF"} />
  );

  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );

  const dyna = {
    placeholder: "Search for Destination, City or Hotel",
    speed: 30,
    stay: 1000,
  };
  const [charIdx, setCharIdx] = useState(0);
  const [placeholderText, setPlaceholderText] = useState("");
  const [reverse, setReverse] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    updatePlaceholder();
    return () => clearTimeout(timeoutRef.current);
  }, [charIdx, reverse]);

  const updatePlaceholder = () => {
    const placeholder = dyna.placeholder;
    setPlaceholderText(placeholder.substring(0, charIdx + 1));

    if (reverse) {
      if (charIdx < 0) {
        timeoutRef.current = setTimeout(() => setReverse(false), dyna.speed);
      } else {
        timeoutRef.current = setTimeout(
          () => setCharIdx(charIdx - 1),
          dyna.speed
        );
      }
    } else {
      if (charIdx >= placeholder.length) {
        timeoutRef.current = setTimeout(() => setReverse(true), dyna.stay);
      } else {
        timeoutRef.current = setTimeout(
          () => setCharIdx(charIdx + 1),
          dyna.speed
        );
      }
    }
  };

  return (
    <>
      <SelectComponent
        allowSearchCard={true}
        onSearch={(e) => setsearchValue(e)}
        handleChange={(e) => {
          setsearchBoxData((o) => {
            return { ...o, destinationName: e };
          });
        }}
        filterOption={false}
        value={
          searchCardData?.destinationName
            ? searchCardData?.destinationName
            : state?.destination !== "nearby"
              ? state?.destination
              : "nearby"
        }
        suffixIcon={null}
        notFoundContent={loading ? <Spin size="small" /> : null}
        label={<b className="placeSearch">Where would you like to go?</b>}
        star="none"
        options={dropdownOptionsArray.map((item) => {
          return {
            value: item.hotelName,
            label: (
              <>
                {item.icon === "place" ? locationIcon : buildingIcon}
                &nbsp;{item.hotelName}
              </>
            ),
          };
        })}
        className={"serchInputcard"}
        placeholder={
          <p className="text-body-tertiary">
            {searchIcon} {placeholderText}
          </p>
        }
        prefix={
          <FontAwesomeIcon color={"#1675DF"} icon={faMapMarkerAlt} />
        }
        dropdownRender={(menu) => (
          <div>
            {menu}

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 8,
                }}
              >
                <Spin />
              </div>
            )}
          </div>
        )}
      />
      {destinationError && <p className="re-err fs-10">Please enter your destination</p>}
    </>
  );
}


export default SearchInput;