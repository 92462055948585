import React, { useEffect, useState, useRef } from "react";
import "./SearchBar.scss";
import NearByIconSvg from "../../assets/searchbar/locationIcon.svg";
import SearchIcon from "../../assets/searchbar/search_icons.svg";
import BackIcon from "../../assets/searchbar/backHeader-icon.svg";
import SBuildingIcon from "../../assets/searchbar/sugestbuild.svg";
import SLocationIcon from "../../assets/searchbar/sugestLocation.svg";
import NoResultIcon from "../../assets/searchbar/no-results-img.png";
import {
  DestinationGetAll
} from "../../services/utils/userUtils/landingPage";
import { useDispatch, useSelector } from "react-redux";
import { setSearchCardFocus } from "../../reduxData/sliceData/searchCardReducer";
import { setSearchToggle, setSearchCardData } from "../../reduxData/sliceData/searchCardReducer";
import { hotelAdvanceSearch } from "../../services/utils/userUtils/hotelutils";
import { useNavigate } from "react-router-dom";

<>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
    crossOrigin="anonymous"
  />
  <script
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    crossOrigin="anonymous"
  ></script>
</>;

const SearchBar = () => {
  const dispatch = useDispatch();
  const [popularCities, setpopularCities] = useState([]);
  const [searchedLocation, setsearchedLocation] = useState([]);
  const [showSearchedItem, setshowSearchedItem] = useState(false);
  const [cityName, setCityName] = useState();
  const routeSearchIn = "/hotels/search-hotels-in";
  const navigate = useNavigate();
  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );

  const allCities = ["Cochin", "Fort Kochi", "Cherai", "Vyttila", "South Railway station", "North Railway Station", "Kakkanad", "Alleppey", "Alleppey Beach", "Trivandrum", "Calicut", "Munnar", "Kovalam", "Vagamon", "Wayanad", "Kottayam", "Kumarakom", "Trissur", "Kannur", "Kollam", "Palakkad", "Goa", "Ooty", "Mumbai", "Coorge", "Bangalore"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await DestinationGetAll();
        const popularData = res?.data?.response
          ?.filter(x => x.isSelected === true)
          .map((v) => ({
            image: `${process.env.REACT_APP_S3_BUCKET_URL}/images/destination/${v.destinationImage[0]}`,
            city: v.destinationName,
            url: `/hotels/search-hotels-in-${v.destinationName}-hotels.html`
          }));

        setpopularCities(popularData);
      } catch (error) {
        console.error("Error fetching destination data:", error);
      }
    };

    fetchData();
    document.getElementById("searchbarinput").focus();
  }, []);

  const searchValChange = (e) => {
    setshowSearchedItem(false);
    hotelSearchDropdown(e.target.value);
  }

  const hotelSearchDropdown = async (e) => {
    try {
      if (e.length >= 3) {
        const res = await hotelAdvanceSearch(e);

        if (res?.data?.length > 0) {

          let result = res?.data.map(function (obj) {
            return JSON.stringify(obj);
          }).filter(function (value, index, self) {
            return self.indexOf(value) === index;
          }).map(function (value) {
            return JSON.parse(value);
          });

          setsearchedLocation(result);

          setshowSearchedItem(true);
        }
      } else {
        setsearchedLocation([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const dyna = {
    placeholder: "Search for Destination, City or Hotel",
    speed: 30,
    stay: 1000,
  };
  const [charIdx, setCharIdx] = useState(0);
  const [placeholderText, setPlaceholderText] = useState("");
  const [reverse, setReverse] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    updatePlaceholder();
    return () => clearTimeout(timeoutRef.current);
  }, [charIdx, reverse]);

  const updatePlaceholder = () => {
    const placeholder = dyna.placeholder;
    setPlaceholderText(placeholder.substring(0, charIdx + 1));

    if (reverse) {
      if (charIdx < 0) {
        timeoutRef.current = setTimeout(() => setReverse(false), dyna.speed);
      } else {
        timeoutRef.current = setTimeout(
          () => setCharIdx(charIdx - 1),
          dyna.speed
        );
      }
    } else {
      if (charIdx >= placeholder.length) {
        timeoutRef.current = setTimeout(() => setReverse(true), dyna.stay);
      } else {
        timeoutRef.current = setTimeout(
          () => setCharIdx(charIdx + 1),
          dyna.speed
        );
      }
    }
  };

  function checkDestination() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {

        const currentLat = position.coords.latitude;
        const currentLng = position.coords.longitude;
        if (currentLat && currentLng) {
          const response = await fetch(
            `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${currentLng},${currentLat}`
          );

          const data = await response.json();
          setCityName(data?.address?.City);
        }
      });
    }
  }

  const locateNearBy = () => {
    removeSearchBar();
    dispatch(setSearchToggle(1));
    if (!cityName) {
      alert("To see hotels in your area, please grant location access through your browser settings. This will allow us to provide a personalized hotel search experience based on your location.");
    } else {
      navigate(
        cityName == "Kochi"
          ? `${routeSearchIn}-${cityName}/cochin-hotels.html`
          : cityName == "Kozhikode"
            ? `${routeSearchIn}-calicut/${cityName}-hotels.html`
            : `${routeSearchIn}-${cityName}-hotels.html`,
        {
          state: { destination: "nearby" },
        }
      );
    }
  }

  const redirectToPage = (url, destination) => {

    if (destination) {
      const payload = { ...searchCardData };
      payload.destinationName = destination;
      dispatch(setSearchCardData(payload));
    }
    removeSearchBar();

    dispatch(setSearchToggle(1));
    navigate(url);
  }

  const removeSearchBar = () => {
    dispatch(setSearchCardFocus(false));
  }

  useEffect(() => {
    checkDestination();
  }, []);

  return (
    <div className="mobileCatagorymaindv">
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 p-0">
            <div className="navSearchMaindivs">
              <button className="backSearch" onClick={removeSearchBar}>
                <img src={BackIcon} alt="" />
              </button>
              <div className="input_navLiastsearch position-relative w-100">
                <input
                  type="search"
                  name=""
                  className="Searchcatagory"
                  id="searchbarinput"
                  placeholder={placeholderText}
                  onChange={searchValChange}
                />
                <img
                  src={SearchIcon}
                  alt=""
                  className="searchIcon"
                />
              </div>
            </div>

            <div className="popularCitiesSection">
              <h4>Popular Cities</h4>
              <div className="verticle_overflow overflow-auto mt-2">
                <div className="verticle_citiesSets d-flex gap-2">
                  <button className="selected_cities" onClick={locateNearBy}>
                    <img src={NearByIconSvg} alt="" className="locationIcon" height={16} />
                    <p>Near me</p>
                  </button>

                  {popularCities.map((val) => {
                    return (
                      <button className="citiessugested" key={val.city} onClick={() => redirectToPage(val.url, val.city)}>
                        <img src={val.image} alt="" className="citieIcon" />
                        <p>{val.city}</p>
                      </button>
                    );
                  })}

                </div>
              </div>
            </div>

            <div className="AllcitiesListings mt-4">
              {(searchedLocation.length > 0 || !showSearchedItem) && <p>All Cities</p>}
              <ul className="m-0 p-0 mt-2">

                {showSearchedItem &&
                  (
                    searchedLocation.length > 0 ?
                      searchedLocation?.map((val, index) => {
                        return (
                          <li key={index} className="m-1">
                            <button className="cities-Listing-button" onClick={() => redirectToPage(`${routeSearchIn}-${val.hotelName}-hotels.html`, val.hotelName)}>
                              <img className="me-2" src={"building" == val.icon ? SBuildingIcon : SLocationIcon} alt="" />
                              {val.hotelName}
                            </button>
                          </li>
                        );
                      })
                      :
                      <div className="NoResultssearchdesigns text-center w-75 m-auto pt-4 pb-2">
                        <img src={NoResultIcon} alt="" className="imgResult" />
                        <h4>Sorry!</h4>
                        <p>We are not able to find hotels in this location. Explore near by hotels</p>
                      </div>
                  )
                }

                {!showSearchedItem &&

                  allCities.map((val, index) => {
                    return (
                      <li key={index}>
                        <button className="cities-Listing-button" onClick={() => redirectToPage(`${routeSearchIn}-${val == "Cochin" ? "Kochi" : val}-hotels.html`, val)}>{val}</button>
                      </li>
                    );
                  })
                }

              </ul>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
